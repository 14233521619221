<template>
  <div class="p-5">
    <h1>{{ $t("PRIVACY_POLICY_TITLE") }}</h1>
    <p style="white-space: pre-wrap">{{ $t("PRIVACY_POLICY_PARAGRAPH_1") }}</p>
    <h2>{{ $t("INFORMATION_COLLECTION") }}</h2>
    <p>
      {{ $t("INFORMATION_COLLECTION_PARAGRAPH_1") }}
    </p>
    <h3>{{ $t("DATA_COLLECTED") }}</h3>
    <h4>{{ $t("PERSONAL_DATA") }}</h4>
    <p>
      {{ $t("PERSONAL_DATA_PARAGRAPH_1") }}
    </p>
    <ul class="px-4">
      <li>{{ $t("PERSONAL_DATA_ITEM_1") }}</li>
      <li>{{ $t("PERSONAL_DATA_ITEM_2") }}</li>
    </ul>
    <h4>{{ $t("USAGE_DATA") }}</h4>
    <p>
      {{ $t("USAGE_DATA_PARAGRAPH_1") }}
    </p>
    <h4>{{ $t("TRACKING_DATA") }}</h4>
    <p style="white-space: pre-wrap">
      {{ $t("TRACKING_DATA_PARAGRAPH_1") }}
    </p>
    <ul class="px-4">
      <li>
        <strong>{{ $t("TRACKING_DATA_ITEM_1_STRONG") }}</strong
        >{{ $t("TRACKING_DATA_ITEM_1") }}
      </li>
      <li>
        <strong>{{ $t("TRACKING_DATA_ITEM_2_STRONG") }}</strong
        >{{ $t("TRACKING_DATA_ITEM_2") }}
      </li>
      <li>
        <strong>{{ $t("TRACKING_DATA_ITEM_3_STRONG") }}</strong
        >{{ $t("TRACKING_DATA_ITEM_3") }}
      </li>
    </ul>
    <h3>{{ $t("USE_OF_DATA") }}</h3>
    <p>{{ $t("USE_OF_DATA_PARAGRAPH_1") }}</p>
    <ul class="px-4">
      <li>{{ $t("USE_OF_DATA_ITEM_1") }}</li>
      <li>{{ $t("USE_OF_DATA_ITEM_2") }}</li>
      <li>
        {{ $t("USE_OF_DATA_ITEM_3") }}
      </li>
      <li>{{ $t("USE_OF_DATA_ITEM_4") }}</li>
      <li>
        {{ $t("USE_OF_DATA_ITEM_5") }}
      </li>
      <li>{{ $t("USE_OF_DATA_ITEM_6") }}</li>
      <li>{{ $t("USE_OF_DATA_ITEM_7") }}</li>
    </ul>
    <h3>{{ $t("TRANSFER_OF_DATA") }}</h3>
    <p style="white-space: pre-wrap">
      {{ $t("TRANSFER_OF_DATA_PARAGRAPH_1") }}
    </p>
    <h2>{{ $t("DISCLOSURE_OF_DATA") }}</h2>
    <h3>{{ $t("LEGAL_REQUIREMENTS") }}</h3>
    <p>
      {{ $t("LEGAL_REQUIREMENTS_PARGRAPH_1") }}
    </p>
    <ul class="px-4">
      <li>{{ $t("LEGAL_REQUIREMENTS_ITEM_1") }}</li>
      <li>{{ $t("LEGAL_REQUIREMENTS_ITEM_2") }}</li>
      <li>
        {{ $t("LEGAL_REQUIREMENTS_ITEM_3") }}
      </li>
      <li>
        {{ $t("LEGAL_REQUIREMENTS_ITEM_4") }}
      </li>
      <li>{{ $t("LEGAL_REQUIREMENTS_ITEM_5") }}</li>
    </ul>
    <h2>{{ $t("SECURITY_OF_DATA") }}</h2>
    <p>
      {{ $t("SECURITY_OF_DATA_PARAGRAPH_1") }}
    </p>
    <h2>{{ $t("SERVICE_PROVIDERS") }}</h2>
    <p style="white-space: pre-wrap">
      {{ $t("SERVICE_PROVIDERS_PARAGRAPH_1") }}
    </p>
    <h3>{{ $t("ANALYTICS") }}</h3>
    <p>
      {{ $t("ANALYTICS_PARAGRAPH_1") }}
    </p>
    <ul class="px-4">
      <li>
        <strong>{{ $t("ANALYTICS_ITEM_1_STRONG") }}</strong
        >{{ $t("ANALYTICS_ITEM_1") }}
      </li>
    </ul>
    <p>
      {{ $t("ANALYTICS_PARAGRAPH_2") }}
      <a href="https://policies.google.com/privacy?hl=en"
        >https://policies.google.com/privacy?hl=en</a
      >
    </p>
    <h2>{{ $t("LINKS_TO_OTHER_SITES") }}</h2>
    <p style="white-space: pre-wrap">
      {{ $t("LINKS_TO_OTHER_SITES_PARAGRAPH_1") }}
    </p>
    <h2>{{ $t("CHILDRENS_PRIVACY") }}</h2>
    <p style="white-space: pre-wrap">
      {{ $t("CHILDRENS_PRIVACY_PARAGRAPH_1") }}
    </p>
    <h2>{{ $t("CHANGES_TO_PRIVACY_POLICY") }}</h2>
    <p style="white-space: pre-wrap">
      {{ $t("CHANGES_TO_PRIVACY_POLICY_PARAGRAPH_1") }}
    </p>
    <h2>{{ $t("CONTACT_US") }}</h2>
    <p>
      {{ $t("CONTACT_US_PARAGRAPH_1") }}
    </p>
    <ul class="px-4">
      <li>{{ $t("CONTACT_US_ITEM_1") }}</li>
    </ul>
    <div style="height: 150px"></div>
  </div>
</template>

<script>
export default {
  name: "PrivecyPolicy",
};
</script>

<style scoped>
h1 {
  margin-bottom: 40px;
  font-weight: bold;
}
h2 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: bold;
}
h3 {
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: bold;
}
p {
  margin-bottom: 30px;
  font-size: 18px;
}
</style>
